import transactions from "../../services/transactions";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_ALL_TRANSACTIONS = "GET_ALL_TRANSACTIONS";
export const GET_ALL_TRANSACTIONS_FILTER = "GET_ALL_TRANSACTIONS_FILTER";
export const CURRENT_FILTERS="CURRENT_FILTERS";
export const GET_TRANSACTIONS_EXCHANGE_ORDERS="GET_TRANSACTIONS_EXCHANGE_ORDERS"
export function getTransactionsCurrency(id, token, currency, lastId) {
  return async (dispatch, getState) => {
    try {
      const response = await transactions.getTransactionsByCurrency(
        id,
        token,
        currency,
        lastId
      );
      dispatch({
        type: GET_TRANSACTIONS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_TRANSACTIONS,
        payload: error.response,
      });
    }
  };
}
export function getAllTransactions(id, token, lastId, status) {
  return async (dispatch, getState) => {
    try {
      const response = await transactions.getAllTransactions(id, token, lastId, status);
      dispatch({
        type: GET_ALL_TRANSACTIONS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_TRANSACTIONS,
        payload: error.response,
      });
    }
  };
}
export function getAllTransactionsOrders(id, token) {
  return async (dispatch, getState) => {
    try {
      const response = await transactions.getAllTransactionsAndOrders(id, token);
      dispatch({
        type: GET_TRANSACTIONS_EXCHANGE_ORDERS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_TRANSACTIONS_EXCHANGE_ORDERS,
        payload: error.response,
      });
    }
  };
}
export function getAllTransactionsByFilter(
  id,
  token,
  currency,
  type,
  minDate,
  maxDate,
  lastId
) {
  return async (dispatch, getState) => {
    try {
      const response = await transactions.getAllTransactionsByFilter(
        id,
        token,
        currency,
        type,
        minDate,
        maxDate,
        lastId
      );
      dispatch({
        type: GET_ALL_TRANSACTIONS_FILTER,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_TRANSACTIONS_FILTER,
        payload: error.response,
      });
    }
  };
}
export function currentFilters(filters) {
  return (dispatch, getState) => {
      dispatch({
        type: CURRENT_FILTERS,
        payload: filters,
      });
  };
}