import React, { Component } from "react";
import {
  Button,
  Space,
  Typography,
  Row,
  Col,
  Card,
  Input,
  Divider,
  Form,
  Select,
  Image,
  Tag,
  message,
} from "antd";
import "./send.css";
import { withTranslation } from "react-i18next";
import COLOR from "../../const/colors";
//import SwapVertical from "@2fd/ant-design-icons/lib/SwapVertical";
import {
  WalletFilled,
  EditFilled,
  RightOutlined,
  LoadingOutlined,
  GlobalOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { asset } from "../../common/assets";
import { getAccounts } from "../../redux/actions/bankingActions";
import {
  selectedAsset,
  infoBeneficiaryToSend,
} from "../../redux/actions/navigateActions";
import { getBeneficiaries } from "../../redux/actions/beneficiariesActions";
import { currentToken } from "../../redux/actions/tokenActions";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";
const { Option, OptGroup } = Select;
const { Text } = Typography;
const { TextArea } = Input;
const formRef = React.createRef();
const reg = /^[A-Za-z\d\-\.&/\s]+$/; //eslint-disable-line
//const regRepeat = /(.)\1{6}/;
class Send extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      activeInput: this.props.nativeCurrency,
      listAssetFiat: [],
      listAssetCrypto: [],
      redirect: false,
      beneficiaries: [],
      loading: false,
      showMessageSendMax: false,
      loadingBeneficiaries: false,
    };
  }
  componentDidMount() {
    this._isMounted = true;
    this.filterCurrencyAvailable();
    if (this.props.assetActive !== "") {
      this.getBeneficiaries(this.props.selectedNetwork);
      this.focusInputAmount();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.assetModal !== this.props.assetModal) {
      // this.props.handleAsset(this.props.assetModal);   -----Seguimiento
    }
    if (this.props.amountSend !== "" && this.props.amountSend !== null) {
      this.adjustFontSize();
    }
    if (
      this.props.amountSendFiat !== "" &&
      this.props.amountSendFiat !== null
    ) {
      this.adjustFontSizeFiat();
    }
    if (this.props.source === "wallet") {
      if (prevProps.asset !== this.props.asset) {
        this.props.handleAsset(this.props.asset.key, this.props.asset.id);
        this.setState({ activeInput: this.props.nativeCurrency });
      }
    }
    if (prevProps.assetActive !== this.props.assetActive) {
      this.setState({ activeInput: this.props.nativeCurrency });
      this.getBeneficiaries(this.props.selectedNetwork);
      this.props.handleNetwork(this.props.selectedNetwork);
      this.focusInputAmount();
    }
  }
  focusInputAmount() {
    if (this.props.source === "header") {
      if (document.getElementById("resize-number-fiat-header") !== null) {
        document.getElementById("resize-number-fiat-header").focus();
      }
      if (document.getElementById("resize-number-header") !== null) {
        document.getElementById("resize-number-header").focus();
      }
    } else {
      if (document.getElementById("resize-number") !== null) {
        document.getElementById("resize-number").focus();
      }
      if (document.getElementById("resize-number-fiat") !== null) {
        document.getElementById("resize-number-fiat").focus();
      }
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleAsset(value) {
    this.props.infoBeneficiaryToSend("");
    let currency = value.split("_")[0];
    let id = value.split("_")[1];
    document.getElementById("inputsForm").reset();
    formRef.current.resetFields();
    this.setState({ activeInput: this.props.nativeCurrency });
    this.props.handleAsset(currency, id);
  }

  handleTypeInput() {
    this.props.clearAmounts();
    if (this.state.activeInput === this.props.assetActive.value) {
      this.setState({ activeInput: this.props.nativeCurrency });
    } else {
      this.setState({ activeInput: this.props.assetActive.value });
    }
  }
  filterCurrencyAvailable() {
    let accountsInfo = this.props.banking.data.data.accounts;
    let detail = this.props.banking.data.data.native_balances.currencies;
    let arrayCurrencies = [];
    for (let objCurrency of accountsInfo) {
      let objCurrencies = {};
      let data = asset.assets.find(
        (element) => element.value === objCurrency.currency
      );
      objCurrencies.icon = data.icon;
      objCurrencies.symbol = data.symbol;
      objCurrencies.name = data.name;
      objCurrencies.value = objCurrency.currency;
      objCurrencies.type = objCurrency.type;
      objCurrencies.id = objCurrency._id;
      let findLengthAccounts = detail.find(
        (element) => element.currency === objCurrency.currency
      );
      objCurrencies.alias =
        findLengthAccounts.accounts !== undefined &&
        findLengthAccounts.accounts?.length > 1
          ? objCurrency.alias
          : null;
      arrayCurrencies.push(objCurrencies);
    }
    let fiat = arrayCurrencies.filter((element) => element.type === "FIAT");
    let crypto = arrayCurrencies.filter((element) => element.type === "CRYPTO");

    this.setState(
      {
        listAssetFiat: fiat,
        listAssetCrypto: crypto,
      },
      () => {
        this.focusInputAmount();
        if (this.props.beneficiaryInfo) {
          this.props.handleAsset(
            this.props.beneficiaryInfo.currency,
            this.props.asset.id
          );
        } else if (this.props.source === "wallet") {
          this.setState({ activeInput: this.props.nativeCurrency });
          this.props.handleAsset(this.props.asset.key, this.props.asset.id); //value for default
        } else {
          if (this.props.assetModal !== "") {
            this.props.handleAsset(
              this.props.assetModal.key,
              this.props.assetModal.id
            ); //value for default
          } else {
            if (this.props.onlyFiat === true) {
              this.props.handleAsset(fiat[0].value, fiat[0].id);
            } else {
              this.props.handleAsset(
                arrayCurrencies[0].value,
                arrayCurrencies[0].id
              );
            }
          }
        }
      }
    );
  }

  adjustFontSize = () => {
    if (this.props.source === "wallet") {
      if (this.props.amountSend.length !== undefined) {
        if (isMobileOnly) {
          const fontSize = 10 - this.props.amountSend.toString().length * 0.2;
          if (document.getElementById("resize-number") !== null) {
            document.getElementById("resize-number").style.fontSize =
              fontSize + "vw";
          }
        } else {
          const fontSize = 2.2 - this.props.amountSend.toString().length * 0.05;
          if (document.getElementById("resize-number") !== null) {
            document.getElementById("resize-number").style.fontSize =
              fontSize + "vw";
          }
        }
      }
    } else if (this.props.source === "header") {
      if (this.props.amountSend.length !== undefined) {
        if (isMobileOnly) {
          const fontSize = 10 - this.props.amountSend.toString().length * 0.2;
          if (document.getElementById("resize-number-header") !== null) {
            document.getElementById("resize-number-header").style.fontSize =
              fontSize + "vw";
          }
        } else {
          const fontSize = 2.8 - this.props.amountSend.length * 0.06;
          if (document.getElementById("resize-number-header") !== null) {
            document.getElementById("resize-number-header").style.fontSize =
              fontSize + "vw";
          }
        }
      }
    }
  };
  adjustFontSizeFiat = () => {
    if (this.props.source === "wallet") {
      if (this.props.amountSendFiat.length !== undefined) {
        if (isMobileOnly) {
          const fontSize =
            12 - this.props.amountSendFiat.toString().length * 0.2;
          if (document.getElementById("resize-number-fiat") !== null) {
            document.getElementById("resize-number-fiat").style.fontSize =
              fontSize + "vw";
          }
        } else {
          const fontSize =
            2.5 - this.props.amountSendFiat.toString().length * 0.05;
          if (document.getElementById("resize-number-fiat") !== null) {
            document.getElementById("resize-number-fiat").style.fontSize =
              fontSize + "vw";
          }
        }
      }
    } else if (this.props.source === "header") {
      if (this.props.amountSendFiat.length !== undefined) {
        if (isMobileOnly) {
          const fontSize = 12 - this.props.amountSendFiat.length * 0.2;
          if (document.getElementById("resize-number-fiat-header") !== null) {
            document.getElementById(
              "resize-number-fiat-header"
            ).style.fontSize = fontSize + "vw";
          }
        } else {
          const fontSize = 3 - this.props.amountSendFiat.length * 0.06;
          if (document.getElementById("resize-number-fiat-header") !== null) {
            document.getElementById(
              "resize-number-fiat-header"
            ).style.fontSize = fontSize + "vw";
          }
        }
      }
    }
  };
  addBeneficiary = () => {
    if (this.props.source !== "wallet") {
      this.props.closeModal();
    }
    let asset = {
      key: this.props.assetActive.value,
      source: "BENEFICIARY",
      id: null,
    };
    this.props.selectedAsset(asset);
    if (this.props.source === "wallet") {
      this.props.handleTab("2");
    }
  };
  async getBeneficiaries(selectedNetwork) {
    try {
      this.setState({ loadingBeneficiaries: true });
      let currency = this.props.assetActive.value;
      let userType = this.props.dataUser.data?.data?.user?.type;
      let accountData = this.props.banking.data.data.account_holder;
      let accountHolder =
        accountData.name +
        " " +
        (accountData.last_name !== undefined ? accountData.last_name : "");
      let network = selectedNetwork;
      this.setState({ beneficiaries: [], loadingBeneficiaries: true });
      await this.props.getBeneficiaries(
        this.props.token.clientId,
        this.props.token.current,
        currency,
        network
      );
      let resp = this.props.beneficiariesList;
      if (resp.headers !== undefined) {
        let newAccessToken = resp.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (resp.status === 200) {
        let beneficiariesResponse =
          userType.includes("LITE") && this.props.assetActive.type === "FIAT"
            ? resp.data.data.external_account_whitelist
            : resp.data.data.beneficiaries;
        let array = [];
        for (let value of beneficiariesResponse) {
          let objData = {};
          objData.id =
            userType.includes("LITE") && this.props.assetActive.type === "FIAT"
              ? value._id
              : value.beneficiary_id;
          objData.currency = value.currency;
          objData.description = value.description;
          objData.type = value.type;
          let typeTraslated =
            value.type === "INTERNAL"
              ? this.props.t("beneficiaries.internal")
              : this.props.t("beneficiaries.external");
          objData.descriptionToShow = value.description + " - " + typeTraslated;
          if (value.account_holder !== undefined) {
            objData.accountHolder = value.account_holder;
          }
          if (value.account_data !== undefined) {
            Object.entries(value.account_data).forEach(([key, val]) => {
              if (key === "networks") {
                for (let dataAccount of val) {
                  objData.network = dataAccount.network;
                  objData.address = dataAccount.address;
                  array.push(objData);
                }
              }
              if (key === "address") {
                objData.address = val;
              }
              if (key === "network") {
                objData.network = val;
              }
              if (key === "account_number") {
                objData.account = val;
              }
              if (key === "sort_code") {
                objData.sortCode = val;
              }
              if (key === "bic") {
                objData.bic = val;
              }
              if (key === "iban") {
                objData.iban = val;
              }
            });
            if (userType.includes("LITE")) {
              if (value.currency === "GBP") {
                objData.description =
                  accountHolder +
                  " - " +
                  objData.account +
                  " - " +
                  objData.sortCode;
              }
              if (value.currency === "EUR") {
                objData.description = accountHolder + " - " + objData.iban;
              }
            }
          }
          array.push(objData);
        }
        if (this._isMounted) {
          this.setState(
            {
              beneficiaries: array,
              loadingBeneficiaries: false,
            },
            () => {
              let info = this.props.beneficiaryInfo;
              if (info) {
                let option = {
                  key: info.id,
                  value: info.description,
                };

                this.props.handleBeneficiary(info.description, option);
              }
            }
          );
        }
      } else if (resp.status === 400) {
        this.setState({ loadingBeneficiaries: false, beneficiaries: [] });
        if (resp.data.error_details.validation_error !== undefined) {
          let messageError = resp.data.error_details.validation_error;

          message.error(
            this.props.t("beneficiaries.messages." + messageError),
            10
          );
        } else {
          message.error(resp.data.message);
        }
      } else if (
        resp.status === 401 ||
        resp.status === 422 ||
        resp.status === 403 ||
        resp.status === 404 ||
        resp.status === 500
      ) {
        if (this._isMounted) {
          this.setState({ loading: false, beneficiaries: [] });
        }
      }
    } catch (error) {
      if (this._isMounted) {
        this.setState({ loading: false });
      }
    }
  }
  validateRepeatedCharacters(text) {
    let cont = 0;
    let tenmText = [];
    let backUpText = text.replace(" ", "");
    for (let charRact of backUpText) {
      tenmText.push(charRact.toLowerCase());
    }
    let firstElement = tenmText[0];
    for (let charItem of tenmText) {
      if (charItem === firstElement) {
        cont = cont + 1;
      }
    }
    if (cont === backUpText.length) {
      return true;
    }
    return false;
  }
  disabledButton() {
    let minWithdrawal = 0;
    let reference = this.props.note;
    let balance = parseFloat(this.props.assetActiveBalance);
    let balanceNative = parseFloat(this.props.nativeBalance);
    minWithdrawal = parseFloat(this.props.limits.min_withdrawal);
    let minLocalCurrency =
      minWithdrawal * parseFloat(this.props.rateCurrent.rate);
    let maxWithdrawal = parseFloat(this.props.limits.max_withdrawal);

    if (this.props.assetActive.type === "CRYPTO") {
      if (this.state.activeInput === this.props.nativeCurrency) {
        if (
          this.props.beneficiary === "" ||
          this.props.amountSendFloat === 0 ||
          this.props.amountSendFloat === undefined ||
          balance < this.props.amountSendFloat ||
          this.props.amountSendFloat < minWithdrawal ||
          this.props.note === "" ||
          !reg.test(this.props.note) ||
          this.props.status !== "ACTIVE" ||
          this.props.payments?.enabled === false ||
          (this.props.amountSendFloat > maxWithdrawal && maxWithdrawal !== -1)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          this.props.beneficiary === "" ||
          this.props.amountSendFiatFloat === 0 ||
          this.props.amountSendFiatFloat === undefined ||
          balanceNative < this.props.amountSendFiatFloat ||
          this.props.amountSendFiatFloat < minLocalCurrency ||
          this.props.note === "" ||
          !reg.test(this.props.note) ||
          this.props.status !== "ACTIVE" ||
          this.props.payments?.enabled === false
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      if (
        this.props.beneficiary === "" ||
        this.props.amountSendFiatFloat === 0 ||
        this.props.amountSendFiatFloat === undefined ||
        balance < this.props.amountSendFiatFloat ||
        this.props.amountSendFiatFloat < minWithdrawal ||
        reference.length < 6 ||
        reference.length > 18 ||
        !reg.test(reference) ||
        this.props.status !== "ACTIVE" ||
        this.props.payments?.enabled === false ||
        (this.props.amountSendFiatFloat > maxWithdrawal &&
          maxWithdrawal !== -1) ||
        this.validateRepeatedCharacters(reference) ||
        reference.charAt(0) === " " ||
        reference.charAt(0) === "-" ||
        reference.charAt(0) === "." ||
        reference.charAt(0) === "&" ||
        reference.charAt(0) === "/"
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
  handleSendAll() {
    let amountMax = 0;
    let newPercentage = 0;
    let dataUser = this.props.dataUser.data?.data?.user;
    let accountsInfo = this.props.banking.data.data.accounts;
    let infoFee = this.findFee();
    let feeType = dataUser?.trading_fee_type;
    let balance = parseFloat(this.props.assetActiveBalance);
    let decimals = this.props.assetActive.type === "FIAT" ? 2 : 8;
    let sourceAccount = accountsInfo.find(
      (element) => element.currency === this.props.assetActive.value
    );

    let maxWithdrawal = parseFloat(this.props.limits.max_withdrawal);
    let withdrawalAmount = parseFloat(infoFee.feeResult.withdrawal_amount);
    if (feeType === "TIERS" && infoFee.feeResult.value_type === "PERCENTAGE") {
      let minWithdrawal = infoFee.feeResult.min_withdrawal_amount;
      newPercentage = 100 - withdrawalAmount;

      amountMax = balance * (newPercentage / 100);
      let fee = (amountMax * withdrawalAmount) / 100;
      if (fee.toFixed(decimals) < minWithdrawal) {
        amountMax = balance - minWithdrawal;
      }
    } else if (
      feeType === "FIXED" ||
      infoFee.feeResult.value_type === "FIXED"
    ) {
      // newPercentage = 100 - withdrawalAmount;
      // amountMax = balance * (newPercentage / 100);
      if (this.props.assetActive.type === "CRYPTO") {
        let accounts = sourceAccount.account_data.networks;
        let network = "";
        if (
          this.props.assetActive.value === "ETH" ||
          this.props.assetActive.value === "BTC"
        ) {
          network = "BASE_ASSET";
        } else {
          network = this.props.selectedNetwork;
        }
        let findNetwork = accounts.find(
          (element) => element.network === network
        );
        if (infoFee.type === "INTERNAL") {
          //fee = infoFee.withdrawal_amount;
          newPercentage = 100 - withdrawalAmount;
          amountMax = balance * (newPercentage / 100);
        } else {
          let findFee = infoFee.feeResult.networks.find(
            (element) => element.network === findNetwork.network
          );
          // fee = parseFloat(findFee.withdrawal_amount);
          newPercentage = 100 - parseFloat(findFee.withdrawal_amount);
          amountMax = balance * (newPercentage / 100);
        }
      } else {
        newPercentage = 100 - withdrawalAmount;
        amountMax = balance * (newPercentage / 100);
      }
    }

    let values = {
      floatValue: parseFloat(amountMax.toFixed(decimals)),
      formattedValue: amountMax.toFixed(decimals).toString(),
      value: amountMax.toFixed(decimals).toString(),
    };

    if (amountMax.toFixed(decimals) > maxWithdrawal && maxWithdrawal !== -1) {
      this.setState({ showMessageSendMax: true });
      setTimeout(() => {
        this.setState({ showMessageSendMax: false });
      }, 10000);
    }

    if (this.props.assetActive.type === "FIAT") {
      this.props.handleAmountFiat(values);
    } else {
      this.props.handleAmount(values);
    }
  }
  findFee() {
    let fee = "";
    let objFee = {};
    let dataUser = this.props.dataUser.data?.data?.user;
    let accountsInfo = this.props.banking.data.data.accounts;
    let infoFee = this.props.fees;
    let feeType = dataUser?.trading_fee_type;
    let sourceAccount = accountsInfo.find(
      (element) => element.currency === this.props.assetActive.value
    );
    if (infoFee) {
      if (feeType === "TIERS" && infoFee.value_type === "PERCENTAGE") {
        if (infoFee.withdrawal_amount !== "0") {
          objFee.showFee =
            infoFee.withdrawal_amount +
            " % " +
            "(min " +
            this.props.assetActive.symbol +
            " " +
            infoFee.min_withdrawal_amount +
            ")";
        } else {
          objFee.showFee =
            this.props.assetActive.symbol + " " + infoFee.withdrawal_amount;
        }
      } else if (feeType === "FIXED" || infoFee.value_type === "FIXED") {
        if (this.props.assetActive.type === "CRYPTO") {
          let accounts = sourceAccount.account_data.networks;
          let network = "";
          if (
            this.props.assetActive.value === "ETH" ||
            this.props.assetActive.value === "BTC"
          ) {
            network = "BASE_ASSET";
          } else {
            network = this.props.selectedNetwork;
          }
          let findNetwork = accounts.find(
            (element) => element.network === network
          );
          if (infoFee.type === "INTERNAL") {
            objFee.showFee =
              feeType === "TIERS"
                ? this.props.assetActive.symbol +
                  " " +
                  infoFee.withdrawal_amount
                : infoFee.withdrawal_amount;
          } else {
            let findFee = infoFee.networks.find(
              (element) => element.network === findNetwork.network
            );

            if (findFee !== undefined) {
              objFee.showFee =
                feeType === "TIERS"
                  ? this.props.assetActive.symbol +
                    " " +
                    findFee.withdrawal_amount
                  : findFee.withdrawal_amount;
            }
          }
        } else {
          objFee.showFee = infoFee.withdrawal_amount;
        }
      }
    }
    objFee.feeResult = infoFee;
    fee = objFee;
    return fee;
  }
  amountValidator = (rule, value, callback) => {
    let amountCryptoValue = this.props.amountSendFloat;
    let balance = this.props.assetActiveBalance;
    let currencyBalance = this.props.assetActive.value;
    let minWithdrawal = parseFloat(this.props.limits.min_withdrawal);
    let maxWithdrawal = this.props.limits.max_withdrawal;
    let messageMax = this.props.t("send.messages.errorMax");
    let messageMinimun = this.props.t("send.messages.errorMinimun");
    let messageEnoughBalance = this.props.t("send.messages.enoughBalance", {
      key: currencyBalance,
    });
    if (amountCryptoValue !== undefined) {
      if (amountCryptoValue > parseFloat(balance)) {
        return Promise.reject(new Error(messageEnoughBalance));
      }

      if (!isNaN(minWithdrawal)) {
        if (amountCryptoValue < minWithdrawal) {
          return Promise.reject(new Error(messageMinimun));
        }
      }
      if (!isNaN(maxWithdrawal) && maxWithdrawal !== "-1") {
        if (amountCryptoValue > maxWithdrawal) {
          return Promise.reject(new Error(messageMax));
        }
      }
      return Promise.resolve();
    }
  };
  amountValidatorFiat = (rule, value, callback) => {
    let maxWithdrawal = this.props.limits.max_withdrawal;
    let minWithdrawal = parseFloat(this.props.limits.min_withdrawal);
    let amountSendValue = this.props.amountSendFiatFloat;
    let balance = this.props.assetActiveBalance;
    let currencyBalance = this.props.assetActive.value;
    let messageEnoughBalance = this.props.t("send.messages.enoughBalance", {
      key: currencyBalance,
    });
    let messageMinimun = this.props.t("send.messages.errorMinimun");
    let messageMax = this.props.t("send.messages.errorMax");
    if (amountSendValue !== undefined) {
      if (amountSendValue > parseFloat(balance)) {
        return Promise.reject(new Error(messageEnoughBalance));
      }
      if (!isNaN(minWithdrawal)) {
        if (amountSendValue < minWithdrawal) {
          return Promise.reject(new Error(messageMinimun));
        }
      }
      if (!isNaN(maxWithdrawal) && maxWithdrawal !== "-1") {
        if (amountSendValue > maxWithdrawal) {
          return Promise.reject(new Error(messageMax));
        }
      }
      return Promise.resolve();
    }
  };
  handleNetwork = (value, option) => {
    this.props.handleNetwork(value);
    formRef.current.resetFields();
    this.getBeneficiaries(value);
  };

  render() {
    let fee = this.findFee();
    let maxWithdrawal = this.props.limits.max_withdrawal;
    let minWithdrawal = parseFloat(this.props.limits.min_withdrawal);
    let balance = this.props.assetActiveBalance;
    let dataAddress =
      this.props.selectedNetwork === "BASE_ASSET"
        ? " (" + this.props.assetActive.value + ")"
        : " (" + this.props.selectedNetwork + ")";
    let errorNote = this.props.t("send.messages.alphanumericCharacterError");
    let errorRepeat = "Cannot contain characters that are all the same";
    let messageMax = this.props.t("send.messages.errorMax");
    let errorMinimunFiat = this.props.t("send.messages.noteMinimun");
    let dataUser = this.props.dataUser.data?.data?.user;
    let userType = dataUser?.type;
    let feeType = dataUser?.trading_fee_type;
    if (userType === undefined) {
      userType = "DAMPAY_USER_CHILDREN";
    }
    return (
      <div>
        {this.props.assetActive.type === "CRYPTO" && (
          <Row
            justify="center"
            gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}
          >
            <Col
              xs={{ span: 3, offset: 0 }}
              sm={{ span: 3, offset: 0 }}
              md={{ span: 3, offset: 0 }}
              lg={{ span: 3, offset: 0 }}
              xl={{ span: 3, offset: 0 }}
              xxl={{ span: 3, offset: 0 }}
            ></Col>
            <Col
              xs={{ span: 18, offset: 0 }}
              sm={{ span: 18, offset: 0 }}
              md={{ span: 18, offset: 0 }}
              lg={{ span: 18, offset: 0 }}
              xl={{ span: 18, offset: 0 }}
              xxl={{ span: 18, offset: 0 }}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Row
                justify="center"
                align="middle"
                gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}
              >
                <Form
                  ref={formRef}
                  autoComplete="off"
                  name="inputsForm"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Form.Item
                    valuePropName="defaultValue"
                    name="amountCrypto"
                    style={{ width: "100%", marginBottom: 0 }}
                    rules={[{ validator: this.amountValidator }]}
                  >
                    <Row gutter={8} justify="center">
                      <Form.Item
                        style={{ marginBottom: 0 }}
                        valuePropName="defaultValue"
                        name="amountCryptoValue"
                      >
                        <NumberFormat
                          allowNegative={false}
                          suffix={" " + this.props.assetActive.symbol}
                          thousandSeparator={true}
                          className="input-amount-dark"
                          id={
                            this.props.source === "wallet"
                              ? "resize-number"
                              : "resize-number-header"
                          }
                          decimalScale={8}
                          style={{
                            fontSize: isMobileOnly
                              ? "13vw"
                              : this.props.source === "wallet"
                              ? "2.9vw"
                              : "2.7vw",
                          }}
                          placeholder={
                            this.props.amountSend +
                            " " +
                            this.props.assetActive.symbol
                          }
                          onValueChange={(values) =>
                            this.props.handleAmount(values)
                          }
                          value={
                            this.props.amountSendFloat === 0
                              ? null
                              : this.props.amountSend
                          }
                        />
                      </Form.Item>
                    </Row>
                  </Form.Item>
                </Form>
              </Row>
            </Col>

            <Col
              xs={{ span: 3, offset: 0 }}
              sm={{ span: 3, offset: 0 }}
              md={{ span: 3, offset: 0 }}
              lg={{ span: 3, offset: 0 }}
              xl={{ span: 3, offset: 0 }}
              xxl={{ span: 3, offset: 0 }}
              style={{
                textAlign: "center",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            ></Col>
          </Row>
        )}
        {this.props.assetActive.type === "FIAT" && (
          <Row
            justify="center"
            gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}
          >
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Form name="inputsForm" ref={formRef} autoComplete="off">
                <Form.Item
                  valuePropName="defaultValue"
                  name="amountFiat"
                  style={{
                    top: 20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    marginBottom: 0,
                    color: COLOR.PRIMARY_TEXT,
                  }}
                  rules={[{ validator: this.amountValidatorFiat }]}
                >
                  <NumberFormat
                    allowNegative={false}
                    thousandSeparator={true}
                    prefix={this.props.assetActive.value === "GBP" ? "£" : "€"}
                    className="input-amount"
                    id={
                      this.props.source === "wallet"
                        ? "resize-number-fiat"
                        : "resize-number-fiat-header"
                    }
                    decimalScale={2}
                    style={{
                      fontSize: isMobileOnly
                        ? "13vw"
                        : this.props.source === "wallet"
                        ? "3vw"
                        : "3.5vw",
                    }}
                    placeholder={
                      this.props.assetActive.value === "GBP"
                        ? "£" + this.props.amountSendFiatFloat
                        : "€" + this.props.amountSendFiatFloat
                    }
                    value={
                      this.props.amountSendFiatFloat === 0
                        ? null
                        : this.props.amountSendFiat
                    }
                    onValueChange={(values) =>
                      this.props.handleAmountFiat(values)
                    }
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        )}
        <Row
          justify="center"
          gutter={[48, { xs: 8, sm: 16, md: 8, lg: 16, xl: 16 }]}
        >
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
          >
            <Space direction="vertical" style={{ width: "100%" }} size="middle">
              <Row justify="center">
                {this.props.payments?.enabled === false && (
                  <Text
                    style={{
                      color: COLOR.ERROR,
                      textAlign: "center",
                      fontSize: 14,
                      marginBottom: 5,
                    }}
                  >
                    {this.props.t("send.messages.disabledPayment", {
                      currency:
                        this.props.assetActive.type === "CRYPTO" &&
                        this.props.payments?.type === "EXTERNAL"
                          ? this.props.t("send.network")
                          : this.props.t("send.currency"),
                      type:
                        this.props.payments?.type === "EXTERNAL"
                          ? this.props.t("transactions.externalAccount")
                          : this.props.t("transactions.internalAccount"),
                    })}
                  </Text>
                )}
                {this.props.status !== "ACTIVE" && (
                  <Text
                    style={{
                      color: COLOR.ERROR,
                      textAlign: "center",
                      fontSize: 14,
                    }}
                  >
                    {this.props.t("wallet.messageStatusAccount", {
                      currency: this.props.assetActive.value,
                      status: this.props.t(
                        "wallet.status." + this.props.status
                      ),
                    })}
                  </Text>
                )}
                {this.props.showMessageLimit && (
                  <Text
                    style={{
                      color: COLOR.ERROR,
                      textAlign: "center",
                      fontSize: 14,
                    }}
                  >
                    {this.props.messageLimit}
                  </Text>
                )}
                {this.state.showMessageSendMax && (
                  <Text
                    style={{
                      color: COLOR.ERROR,
                      textAlign: "center",
                      fontSize: 14,
                    }}
                  >
                    {messageMax}
                  </Text>
                )}
                <Button
                  className="disabledStyle"
                  disabled={
                    this.props.assetActiveBalance === "0" ||
                    this.props.beneficiary === ""
                  }
                  style={{ borderColor: COLOR.SECONDARY, marginTop: 16 }}
                  onClick={() => this.handleSendAll()}
                >
                  {this.props.t("send.buttonSendAll")}
                </Button>
              </Row>
              <Row justify="center">
                <Card className="card-form" bodyStyle={{ padding: 0 }}>
                  <Form
                    ref={formRef}
                    labelAlign="left"
                    labelCol={{
                      xs: { span: 24 },
                      sm: { span: 24 },
                      md: { span: 8 },
                      lg: { span: 8 },
                      xl: { span: 8 },
                      xxl: { span: 10 },
                    }}
                    wrapperCol={{
                      xs: { span: 24 },
                      sm: { span: 24 },
                      md: { span: 16 },
                      lg: { span: 16 },
                      xl: { span: 16 },
                      xxl: { span: 14 },
                    }}
                    layout="horizontal"
                  >
                    {this.props.assetActive.type === "CRYPTO" && (
                      <>
                        <Form.Item
                          name="network"
                          className="item-send-dark"
                          label={
                            <Text className="label-dark">
                              {this.props.t("send.network")}
                            </Text>
                          }
                          tooltip={{
                            title: this.props.t("receive.messageNetwork", {
                              key:
                                this.props.selectedNetwork !== undefined
                                  ? this.props.selectedNetwork === "BASE_ASSET"
                                    ? this.props.assetActive.value
                                    : this.props.assetActive.value +
                                      " (" +
                                      this.props.selectedNetwork +
                                      ") "
                                  : "",
                            }),
                            icon: (
                              <InfoCircleOutlined
                                style={{
                                  color: COLOR.SECONDARY_TEXT,
                                  fontSize: 18,
                                }}
                              />
                            ),
                          }}
                        >
                          <Input.Group>
                            <Row>
                              <Col
                                span={2}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <GlobalOutlined
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                  }}
                                />
                              </Col>
                              <Col
                                span={22}
                                //offset={1}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {/* <Col
                                xs={{ span: 24, offset: 0 }}
                                sm={{ span: 24, offset: 0 }}
                                md={{ span: 24, offset: 0 }}
                                lg={{ span: 24, offset: 0 }}
                                xl={{ span: 24, offset: 0 }}
                              > */}
                                <div
                                  id={"select-dark-beneficiaries"}
                                  className="ant-select-selector ant-select ant-typography"
                                >
                                  <Select
                                    loading={this.state.loading}
                                    suffixIcon={
                                      this.state.loading ? (
                                        <LoadingOutlined
                                          style={{ color: "#ccc" }}
                                        />
                                      ) : (
                                        <RightOutlined
                                          style={{ color: "#ccc" }}
                                        />
                                      )
                                    }
                                    size="large"
                                    bordered={false}
                                    disabled={
                                      this.props.loadingLimits ||
                                      this.props.loadingFees
                                    }
                                    style={{
                                      color: "#ccc",
                                      fontWeight: "bold",
                                    }}
                                    placeholder={this.props.t("send.network")}
                                    onChange={(value, option) =>
                                      this.handleNetwork(value, option)
                                    }
                                    value={
                                      this.props.selectedNetwork ===
                                      "BASE_ASSET"
                                        ? this.props.assetActive.value
                                        : this.props.selectedNetwork
                                    }
                                  >
                                    {this.props.networks.length !== 0 &&
                                      this.props.networks.map((item, i) => {
                                        return (
                                          <Option
                                            key={item.asset_id}
                                            value={item.network}
                                          >
                                            <div>
                                              <Text
                                                style={{
                                                  color: "#75787C",
                                                  fontSize: 15,
                                                }}
                                              >
                                                {item.network === "BASE_ASSET"
                                                  ? this.props.assetActive.value
                                                  : item.network}
                                              </Text>
                                            </div>
                                          </Option>
                                        );
                                      })}
                                  </Select>
                                </div>
                              </Col>
                            </Row>
                          </Input.Group>
                        </Form.Item>
                        <Divider
                          style={{
                            background: COLOR.PRIMARY_TEXT,
                            margin: 0,
                          }}
                        />
                      </>
                    )}
                    <Form.Item
                      name="beneficiary"
                      className="item-send-dark"
                      label={
                        <Text className="label-dark">
                          {this.props.t("send.to")}
                        </Text>
                      }
                    >
                      <Row>
                        <Col
                          span={2}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <WalletFilled
                            style={{
                              color: COLOR.PRIMARY_TEXT,
                            }}
                          />
                        </Col>
                        <Col
                          xs={{ span: 22, offset: 0 }}
                          sm={{ span: 22, offset: 0 }}
                          md={{ span: 22, offset: 0 }}
                          lg={{
                            span:
                              userType.includes("LITE") &&
                              this.props.assetActive.type === "FIAT" &&
                              this.props.beneficiary !== ""
                                ? this.props.assetActive.value === "EUR"
                                  ? 20
                                  : 19
                                : 22,
                            offset: 0,
                          }}
                          xl={{
                            span:
                              userType.includes("LITE") &&
                              this.props.assetActive.type === "FIAT" &&
                              this.props.beneficiary !== ""
                                ? this.props.assetActive.value === "EUR"
                                  ? 12
                                  : 16
                                : 22,
                            offset: 0,
                          }}
                        >
                          <div
                            id={"select-dark-beneficiaries"}
                            className="ant-select-selector ant-select"
                          >
                            <Select
                              id="beneficiaries"
                              value={this.props.beneficiarySelected.description}
                              loading={this.state.loadingBeneficiaries}
                              suffixIcon={
                                this.state.loadingBeneficiaries ? (
                                  <LoadingOutlined style={{ color: "#ccc" }} />
                                ) : (
                                  <RightOutlined style={{ color: "#ccc" }} />
                                )
                              }
                              size="large"
                              bordered={false}
                              disabled={
                                this.state.loadingBeneficiaries ||
                                this.props.loadingLimits ||
                                this.props.loadingFees
                              }
                              style={{
                                color: "#ccc",
                              }}
                              placeholder={
                                userType.includes("LITE") &&
                                this.props.assetActive.type === "FIAT"
                                  ? this.props.t("send.bankAccount")
                                  : this.props.t("send.beneficiary")
                              }
                              onChange={(value, option) =>
                                this.props.handleBeneficiary(value, option)
                              }
                              dropdownMatchSelectWidth={false}
                              dropdownRender={(menu) => (
                                <div>
                                  {this.state.beneficiaries.length !== 0 &&
                                    menu}
                                  {this.state.beneficiaries.length === 0 && (
                                    <div
                                      style={{
                                        display: "inline-flex",
                                        flexWrap: "nowrap",
                                        padding: 8,
                                      }}
                                    >
                                      <Text
                                        style={{
                                          justifyContent: "center",
                                        }}
                                      >
                                        {this.props.assetActive.type === "FIAT"
                                          ? this.props.t(
                                              "send.noBeneficiariesAccount",
                                              {
                                                key: this.props.assetActive
                                                  .name,
                                              }
                                            )
                                          : this.props.t(
                                              "send.noBeneficiariesAddress",
                                              {
                                                key:
                                                  this.props.assetActive.name +
                                                  dataAddress,
                                              }
                                            )}

                                        {userType.includes("LITE") &&
                                        this.props.assetActive.type ===
                                          "FIAT" ? (
                                          this.props.t("send.noBankAccounts", {
                                            key: this.props.assetActive.value,
                                          })
                                        ) : (
                                          <>
                                            {this.props.t(
                                              "send.noBeneficiaries2"
                                            )}
                                            <Link
                                              to="/detail"
                                              style={{
                                                flex: "none",
                                                cursor: "pointer",
                                                padding: 5,
                                                color: COLOR.GREEN_1,
                                              }}
                                              onClick={() =>
                                                this.addBeneficiary()
                                              }
                                            >
                                              {userType.includes("LITE") &&
                                              this.props.assetActive.type ===
                                                "FIAT"
                                                ? this.props.t(
                                                    "send.bankAccounts"
                                                  )
                                                : this.props.t(
                                                    "send.beneficiaries"
                                                  )}
                                            </Link>
                                            {this.props.t(
                                              "send.noBeneficiaries3"
                                            )}
                                          </>
                                        )}
                                      </Text>
                                    </div>
                                  )}
                                </div>
                              )}
                              optionLabelProp="label"
                            >
                              {this.state.beneficiaries.length !== 0 &&
                                this.state.beneficiaries.map((item, i) => {
                                  return (
                                    <Option
                                      key={item.id}
                                      value={item.description}
                                    >
                                      {this.props.assetActive.type ===
                                      "CRYPTO" ? (
                                        <div>
                                          <Text
                                            style={{
                                              color: "#75787C",
                                              fontSize: 15,
                                            }}
                                          >
                                            {item.address}
                                          </Text>
                                          <br />
                                          <Text style={{ fontSize: 14 }}>
                                            {item.description}
                                            <Tag
                                              style={{ marginLeft: 10 }}
                                              color={
                                                item.type === "INTERNAL"
                                                  ? "#108ee9"
                                                  : "#f50"
                                              }
                                            >
                                              <Text
                                                style={{
                                                  color: COLOR.PRIMARY_TEXT,
                                                  fontWeight: 400,
                                                  marginBottom: 0,
                                                }}
                                              >
                                                {item.type === "INTERNAL"
                                                  ? this.props.t(
                                                      "beneficiaries.internal"
                                                    )
                                                  : this.props.t(
                                                      "beneficiaries.external"
                                                    )}
                                              </Text>
                                            </Tag>
                                          </Text>
                                        </div>
                                      ) : (
                                        <div>
                                          {item.description}
                                          {!userType.includes("LITE") && (
                                            <Tag
                                              style={{ marginLeft: 10 }}
                                              color={
                                                item.type === "INTERNAL"
                                                  ? "#108ee9"
                                                  : "#f50"
                                              }
                                            >
                                              <Text
                                                style={{
                                                  color: COLOR.PRIMARY_TEXT,
                                                  fontWeight: 400,
                                                  marginBottom: 0,
                                                }}
                                              >
                                                {item.type === "INTERNAL"
                                                  ? this.props.t(
                                                      "beneficiaries.internal"
                                                    )
                                                  : this.props.t(
                                                      "beneficiaries.external"
                                                    )}
                                              </Text>
                                            </Tag>
                                          )}
                                        </div>
                                      )}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </div>
                        </Col>
                      </Row>
                    </Form.Item>

                    <Divider
                      style={{ background: COLOR.PRIMARY_TEXT, margin: 0 }}
                    />
                    <Form.Item
                      className="item-send-dark"
                      label={
                        <Text className="label-dark">
                          {this.props.t("send.note")}
                        </Text>
                      }
                    >
                      <Input.Group>
                        <Row>
                          <Col
                            span={2}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <EditFilled
                              style={{
                                color: COLOR.PRIMARY_TEXT,
                              }}
                            />
                          </Col>
                          <Col span={22}>
                            <Form.Item
                              noStyle
                              name="note"
                              rules={
                                this.props.assetActive.type === "CRYPTO"
                                  ? [
                                      ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          if (value !== "") {
                                            // const reg = /^[A-Za-z\d\-\.&/\s]+$/;
                                            if (reg.test(value)) {
                                              return Promise.resolve();
                                            } else {
                                              return Promise.reject(
                                                new Error(errorNote)
                                              );
                                            }
                                          }
                                        },
                                      }),
                                    ]
                                  : [
                                      ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          if (value !== "") {
                                            let cont = 0;
                                            let tenmText = [];
                                            let backUpText = value.replace(
                                              " ",
                                              ""
                                            );
                                            for (let charRact of backUpText) {
                                              tenmText.push(
                                                charRact.toLowerCase()
                                              );
                                            }
                                            let firstElement = tenmText[0];
                                            for (let charItem of tenmText) {
                                              if (charItem === firstElement) {
                                                cont = cont + 1;
                                              }
                                            }
                                            if (
                                              cont === backUpText.length &&
                                              value.length >= 6
                                            ) {
                                              return Promise.reject(
                                                new Error(errorRepeat)
                                              );
                                            }
                                            return Promise.resolve();
                                          }
                                        },
                                      }),
                                      {
                                        max: 18,
                                        message: this.props.t(
                                          "send.messages.noteMaximun"
                                        ),
                                      },
                                      ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          if (value !== "") {
                                            if (reg.test(value)) {
                                              return Promise.resolve();
                                            } else {
                                              return Promise.reject(
                                                new Error(errorNote)
                                              );
                                            }
                                          }
                                        },
                                      }),
                                      ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          if (value !== "") {
                                            if (
                                              value.charAt(0) === " " ||
                                              value.charAt(0) === "-" ||
                                              value.charAt(0) === "." ||
                                              value.charAt(0) === "&" ||
                                              value.charAt(0) === "/"
                                            ) {
                                              return Promise.reject(
                                                new Error(
                                                  "Note must start with letters or numbers."
                                                )
                                              );
                                            } else {
                                              return Promise.resolve();
                                            }
                                          }
                                        },
                                      }),
                                      ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          if (value !== "") {
                                            let val = value.replaceAll(
                                              /[^A-Za-z\d]/g,
                                              ""
                                            );
                                            if (val.length >= 6) {
                                              return Promise.resolve();
                                            } else {
                                              return Promise.reject(
                                                new Error(errorMinimunFiat)
                                              );
                                            }
                                          }
                                        },
                                      }),
                                    ]
                              }
                            >
                              <TextArea
                                style={{
                                  color: COLOR.PRIMARY_TEXT,
                                  background: COLOR.PRIMARY,
                                }}
                                size="large"
                                autoSize={{ minRows: 1, maxRows: 4 }}
                                bordered={false}
                                placeholder={this.props.t(
                                  "send.notePlaceholderFiat"
                                )}
                                value={this.props.note}
                                onChange={(e) => this.props.handleNote(e)}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Input.Group>
                    </Form.Item>

                    <Divider
                      style={{ background: COLOR.PRIMARY_TEXT, margin: 0 }}
                    />
                    <Form.Item
                      className="item-select-dark"
                      label={
                        <Text className="label-dark">
                          {this.props.t("send.payWith")}
                        </Text>
                      }
                    >
                      <div
                        style={{
                          display: "inline-flex",
                          float: "left",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <Image
                          src={this.props.assetActive.icon}
                          width={30}
                          preview={false}
                        />
                        <div
                          id="select-dark-option"
                          className={
                            this.props.asset !== "" &&
                            this.props.source === "wallet"
                              ? "ant-select-selector ant-select ant-select-disabled"
                              : "ant-select-selector ant-select"
                          }
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                              if (option.value) {
                                let val = option.value.split("_")[2];
                                return (
                                  val
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                );
                              }
                            }}
                            suffixIcon={
                              <RightOutlined style={{ color: "#ccc" }} />
                            }
                            size="large"
                            bordered={false}
                            style={{ color: "#ccc" }}
                            disabled={
                              this.props.source === "wallet" ? true : false
                            }
                            value={this.props.labelActive}
                            onChange={(value) => this.handleAsset(value)}
                            optionLabelProp="label"
                          >
                            {this.state.listAssetFiat.length !== 0 && (
                              <OptGroup label="Fiat">
                                {this.state.listAssetFiat.map((item, i) => {
                                  return (
                                    <Option
                                      key={item.id}
                                      value={
                                        item.alias !== null
                                          ? item.value +
                                            "_" +
                                            item.id +
                                            "_" +
                                            item.alias
                                          : item.value +
                                            "_" +
                                            item.id +
                                            "_" +
                                            item.name
                                      }
                                    >
                                      <div
                                        style={{
                                          display: "inline-flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Image
                                          style={{ paddingRight: 5 }}
                                          src={item.icon}
                                          width={30}
                                          preview={false}
                                        />
                                        {item.alias !== null
                                          ? item.alias
                                          : item.name}
                                      </div>
                                    </Option>
                                  );
                                })}
                              </OptGroup>
                            )}
                            {this.state.listAssetCrypto.length !== 0 && (
                              <OptGroup label="Crypto">
                                {this.state.listAssetCrypto.map((item, i) => {
                                  return (
                                    <Option
                                      key={item.id}
                                      value={
                                        item.value +
                                        "_" +
                                        item.id +
                                        "_" +
                                        item.name
                                      }
                                    >
                                      <div
                                        style={{
                                          display: "inline-flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Image
                                          style={{ paddingRight: 5 }}
                                          src={item.icon}
                                          width={30}
                                          preview={false}
                                        />
                                        {item.name}
                                      </div>
                                    </Option>
                                  );
                                })}
                              </OptGroup>
                            )}
                          </Select>
                        </div>
                      </div>
                    </Form.Item>
                  </Form>
                </Card>
              </Row>
              <Row justify="center">
                <Button
                  disabled={this.disabledButton()}
                  block
                  type="primary"
                  style={{
                    height: "60px",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                  onClick={() => this.props.next()}
                >
                  {this.props.t("send.buttonContinue")}
                </Button>
              </Row>

              {minWithdrawal !== "" && !isNaN(minWithdrawal) && (
                <Row justify="space-between">
                  <Text
                    style={{
                      color: "#898996",

                      fontSize: 14,
                    }}
                  >
                    {this.props.t("send.minWithdrawal")}
                  </Text>
                  <Text
                    style={{
                      color: "#898996",
                      marginLeft: 10,
                      fontSize: 14,
                    }}
                  >
                    <NumberFormat
                      allowNegative={false}
                      thousandSeparator={true}
                      displayType="text"
                      prefix={this.props.assetActive.symbol + " "}
                      decimalScale={
                        this.props.assetActive.type === "CRYPTO" ? 8 : 2
                      }
                      value={minWithdrawal}
                    />
                  </Text>
                </Row>
              )}
              {maxWithdrawal !== undefined && maxWithdrawal !== "-1" && (
                <>
                  <Row justify="space-between">
                    <Text
                      style={{
                        color: "#898996",
                        fontSize: 14,
                      }}
                    >
                      {this.props.t("send.maxWithdrawal")}
                    </Text>
                    <Text
                      style={{
                        color: "#898996",
                        marginLeft: 10,
                        fontSize: 14,
                      }}
                    >
                      <NumberFormat
                        allowNegative={false}
                        thousandSeparator={true}
                        displayType="text"
                        prefix={this.props.assetActive.symbol + " "}
                        decimalScale={
                          this.props.assetActive.type === "CRYPTO" ? 8 : 2
                        }
                        value={maxWithdrawal}
                      />
                    </Text>
                  </Row>
                </>
              )}

              {Object.keys(fee).length !== 0 && fee.feeResult !== null && (
                <Row justify="space-between">
                  <Text
                    style={{
                      color: "#898996",

                      fontSize: 14,
                    }}
                  >
                    {this.props.t("send.withdrawalFee")}
                  </Text>
                  <Text
                    style={{
                      color: "#898996",
                      marginLeft: 10,
                      fontSize: 14,
                    }}
                  >
                    {feeType === "TIERS" && fee.showFee}
                    {feeType === "FIXED" && (
                      <NumberFormat
                        allowNegative={false}
                        thousandSeparator={true}
                        displayType="text"
                        prefix={this.props.assetActive.symbol + " "}
                        decimalScale={
                          this.props.assetActive.type === "CRYPTO" ? 8 : 2
                        }
                        value={fee.showFee}
                      />
                    )}
                  </Text>
                </Row>
              )}
              <Row justify="space-between">
                <Text
                  style={{
                    color: "#898996",
                    fontSize: 14,
                  }}
                >
                  {this.props.assetActive.value !== undefined
                    ? this.props.assetActive.value
                    : ""}{" "}
                  balance
                </Text>
                <Text style={{ color: "#898996", fontSize: 14 }}>
                  {this.props.assetActive.type === "CRYPTO" ? (
                    <NumberFormat
                      thousandSeparator={true}
                      displayType="text"
                      suffix={" " + this.props.assetActive.symbol}
                      decimalScale={8}
                      value={balance}
                    />
                  ) : (
                    <NumberFormat
                      thousandSeparator={true}
                      displayType="text"
                      prefix={this.props.assetActive.symbol + " "}
                      decimalScale={2}
                      value={balance}
                    />
                  )}{" "}
                  {this.props.assetActive.value !==
                    this.props.nativeCurrency && (
                    <NumberFormat
                      thousandSeparator={true}
                      displayType="text"
                      prefix={"≈ " + this.props.nativeSymbol + " "}
                      decimalScale={2}
                      value={parseFloat(this.props.nativeBalance)}
                    />
                  )}
                </Text>
              </Row>
            </Space>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    banking: state.banking.accounts,
    asset: state.siderNavigate.asset,
    beneficiariesList: state.beneficiaries.resultBeneficiaries,
    token: state.tokenAccess,
    dataUser: state.authUser.userData,
    onlyFiat: state.authUser.isFiatOnly,
    beneficiaryInfo: state.siderNavigate.infoToSend,
  };
};

const mapDispatchToProps = {
  getAccounts,
  selectedAsset,
  getBeneficiaries,
  currentToken,
  infoBeneficiaryToSend,
};
const SendTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Send)
);

export default SendTranslated;
