import approvalsServices from "../../services/approvals";
import { currentToken } from "./tokenActions";
export const GET_TRANSACTIONS_PENDINGS = "GET_TRANSACTIONS_PENDINGS";
export const SET_LAST_ID_PENDING_TX = "SET_LAST_ID_PENDING_TX";
export function getAllTransactionsByFilter(
  idToken,
  accessToken,
  currency,
  userChildrenId,
  startDate,
  endDate,
  lastId
) {
  return async (dispatch, getState) => {
    try {
      const response =
        await approvalsServices.getAllTransactionsPendingByFilter(
          idToken,
          accessToken,
          currency,
          userChildrenId,
          startDate,
          endDate,
          lastId
        );
      const { current_last_id, transactions } = response.data.data;
      dispatch({
        type: GET_TRANSACTIONS_PENDINGS,
        payload: transactions,
      });
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        //  await this.props.currentToken(newAccessToken);
        dispatch(currentToken(newAccessToken));
      }
     
      dispatch({
        type: SET_LAST_ID_PENDING_TX,
        payload: current_last_id,
      });
    } catch (error) {
      dispatch({
        type: GET_TRANSACTIONS_PENDINGS,
        payload: error.response,
      });
    }
  };
}
